<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">Program Users</h1>
      <div class="d-flex justify-content-end">
        <base-button
          :disabled="$store.state.modals.downloadingReport"
          size="md"
          class="mr-3 title-btn-padding white-outline-button button-font"
          @click="exportProgramUsers" type="secondary">
          <img alt="Image placeholder" class="mt--1" src="/img/svg/export.svg">
          Export
        </base-button>
        <router-link
          :to="{ name: 'admin.program_user.store'}">
          <el-button class="btn-secondary-action title-btn-padding button-font button-hover-effect">Add New</el-button>
        </router-link>
      </div>
    </div>
    <div v-loading="loaders.programUsers" class="mt-4 cardStyle">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap my-3">
        <div class="d-flex" v-if="!selectedRows.length">
          <base-input class="d-inline-block filter-button-width" label="Username" placeholder="Search by Username..."
                      v-model="request.username" type="search" @change="filterChange" @input="filterChange"/>
          <base-input class="d-inline-block filter-button-width ml-3" label="Email" placeholder="Search by Email..."
                      v-model="request.email" type="search" @change="filterChange" @input="filterChange"/>
          <base-input class="d-inline-block filter-button-width ml-3" label="Program Code"
                      placeholder="Search by Program Code..."
                      v-model="request.program_code" type="search" @change="filterChange" @input="filterChange"/>
          <base-input class="d-inline-block filter-button-width ml-3" label="Program Name"
                      placeholder="Search by Program Name..."
                      v-model="request.program_name" type="search" @change="filterChange" @input="filterChange"/>
          <base-input label="Status" class="ml-3">
            <p @click="clearStatusAndGetProgramUsers" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">
              Clear</p>
            <el-select
              class="w-100"
              label="Status"
              placeholder=""
              v-model="request.status"
              @change="filterChange"
            >
              <el-option
                v-for="option in dropdowns.status"
                class="select-danger"
                :value="option.value"
                :label="option.label"
                :key="option.label">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div v-else id="select-option-container" class="mt-4 ml-4">
          <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                       @click="promptMultiRemove"
                       :loading="loaders.checkboxButton.Delete"
          >Delete
          </base-button>
          <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                       @click="handleActivate"
                       :loading="loaders.checkboxButton.Activate"
          >Activate
          </base-button>
          <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                       @click="handleDeactivate"
                       :loading="loaders.checkboxButton.DeActive"
          >Deactivate
          </base-button>
        </div>
      </div>
      <el-table
        row-key="id"
        header-row-class-name="thead-light"
        width="100%"
        :data="response.programUsers"
        @selection-change="sectionListMultipleSelect"
      >
        <el-table-column prop="selected" type="selection" width="50">
        </el-table-column>
        <el-table-column label="USERNAME" :min-width="200">
          <template slot-scope="scope">
            <div class="text-break">{{ scope.row.username }}</div>
          </template>
        </el-table-column>
        <el-table-column label="EMAIL" :min-width="250">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ scope.row.email }}</div>
          </template>
        </el-table-column>
        <el-table-column label="ROLE" width="250">
          <template slot-scope="scope">
            <div class="text-break  ">{{ scope.row.role | programUserRole }}</div>
          </template>
        </el-table-column>
        <el-table-column label="PROGRAM NAME" :min-width="300">
          <template slot-scope="scope">
            <div class="text-break  ">{{ scope.row.sub_user.first_name }}</div>
          </template>
        </el-table-column>
        <el-table-column label="PROGRAM CODE" width="200">
          <template slot-scope="scope">
            <div class="text-break  ">{{ scope.row.sub_user.programmer_code }}</div>
          </template>
        </el-table-column>
        <el-table-column label="CREATED" width="140" align="center">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ scope.row.created | createdAt }}</div>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" width="120" align="center">
          <template slot-scope="scope">
              <span :class="['status',getStatusClass(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatusClass(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" width="240" align="center">
          <div slot-scope="{ $index, row }" class="d-flex">
            <el-tooltip content="View Program User" placement="top">
              <router-link
                :to="{name: 'admin.program_user.show', params: {id: row.id}}">
                <img class="pointer mr-2" src="/img/eye.svg" alt="">
              </router-link>
            </el-tooltip>
            <el-tooltip content="Edit Program User" placement="top">
              <router-link
                :to="{name: 'admin.program_user.edit', params: {id: row.id}}">
                <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" alt="">
              </router-link>
            </el-tooltip>
            <el-tooltip content="Change Password" placement="top">
              <router-link
                :to="{name: 'admin.program_user.change_password', params: {id: row.id}}">
                <img class="pointer mr-2" src="/img/svg/lock.svg" alt="">
              </router-link>
            </el-tooltip>
            <el-tooltip content="Reset security answer" placement="top">
              <img class="pointer mr-2" src="/img/icons/buttons/reset_security_answer.png" alt=""
                   @click="confirmResetSecurityAnswer(row.id)">
            </el-tooltip>
            <el-tooltip content="Resend Dashboard Invite" placement="top">
              <img class="pointer mr-2" src="/img/icons/buttons/resend_invite.png" alt=""
                   @click="confirmResendDashboardInvite(row.id)">
            </el-tooltip>
            <el-tooltip content="Delete" placement="top">
              <img class="pointer mr-2" src="/img/icons/buttons/delete.svg" alt="Delete" @click="promptRemove(row)">
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
      <div class="row">
        <div class="col-12 my-3 px-4">
          <custom-pagination
            class="pagination-no-border float-right"
            v-model="currentPage"
            :per-page="perPage"
            :total="totalPages"
            @input="changePage"
          />
        </div>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeProgramUser"
        @onRemoveMultiple="removeMultipleProgramUsers"
      />
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import CustomPagination from "@/views/Components/Pagination/CustomPagination";
import BackButton from "@/components/Router/BackButton";
import moment from "moment";
import {constants} from "@/util/constants";
import Swal from "sweetalert2";
import {store} from "@/vuex/store";
import fileDownload from "@/mixins/fileDownload";

const RemoveModal = () => import("@/components/Modals/RemoveModal.vue")

export default {
  name: "ProgramUsers",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
    CustomPagination,
    BackButton
  },
  data() {
    return {
      loaders: {
        programUsers: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      btnDraggable: 'btnDraggable',
      request: {
        username: '',
        email: '',
        program_code: '',
        program_name: '',
        status: ''
      },
      dropdowns: {
        status: [
          {value: '', label: 'All Program Users'},
          {value: 1, label: 'Active'},
          {value: 0, label: 'Inactive',},
        ],
      },
      response: {
        programUsers: [],
      },
      currentPage: 1,
      totalPages: 1,
      perPage: 10
    }
  },
  computed: {
    /**
     * Extract the ids from selected rows from table
     * @returns {*[]}
     */
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  },
  filters: {
    /**
     * Reformat created date
     * @param date
     * @returns {string}
     */
    createdAt: function (date) {
      return moment.utc(date).local().format('DD-MMM-YYYY')
    },

    /**
     * Get Program User Role
     * @param role
     * @returns {string}
     */
    programUserRole: function (role) {
      return constants.programUser.getRole[role] ?? ''
    },
  },
  mixins: [
    fileDownload
  ],
  mounted() {
    this.getProgramUsersListing()
  },
  methods: {
    /**
     * Confirm if dashboard invite needed to be resend to this program user
     * @param id
     */
    confirmResendDashboardInvite(id) {
      Swal.fire({
        title: 'Resend Dashboard Invite',
        text: 'Are you sure? You want to resend dashboard invite for this program user.',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        allowOutsideClick: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-secondary-action',
          cancelButton: 'btn btn-primary-action'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.resendDashboardInvite(id)
        }
      })
    },

    /**
     * Resend dashboard invite to this program user
     * @param id
     */
    resendDashboardInvite(id) {
      let vm = this
      vm.loaders.programUsers = true
      const payload = {
        id
      }
      vm.$store.dispatch('ProgramUserModule/_resendDashboardInviteProgramUser', {payload})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Program User Dashboard Invite Resend Successfully!'
          })
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Resend Dashboard Invite',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.programUsers = false
        })
    },

    /**
     * Confirm if security answer's needed to be reset
     * @param id
     */
    confirmResetSecurityAnswer(id) {
      Swal.fire({
        title: 'Reset Security Answer',
        text: 'Are you sure? You want to reset security answer for this program user.',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        allowOutsideClick: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-secondary-action',
          cancelButton: 'btn btn-primary-action'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetSecurityAnswer(id)
        }
      })
    },

    /**
     * Reset Security for Program User
     */
    resetSecurityAnswer(id) {
      let vm = this
      vm.loaders.programUsers = true
      const payload = {
        id
      }
      vm.$store.dispatch('ProgramUserModule/_resetProgramUserSecurityAnswers', {payload})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Program User Security Answer Reset Successfully!'
          })
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Reset Security',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.programUsers = false
        })
    },

    /**
     * Change filter
     */
    filterChange() {
      this.currentPage = 1;
      this.getProgramUsersListing()
    },
    /**
     * Change page
     * @param item
     */
    changePage(item) {
      this.currentPage = item
      this.getProgramUsersListing()
    },

    /**
     * Clear status and get program users listing
     */
    clearStatusAndGetProgramUsers() {
      this.currentPage = 1;
      this.request.status = ''
      this.getProgramUsersListing()
    },

    /**
     * Get Program Users Listing
     */
    getProgramUsersListing() {
      let vm = this
      vm.loaders.programUsers = true
      const payload = {
        page: vm.currentPage,
        username: vm.request.username,
        email: vm.request.email,
        program_code: vm.request.program_code,
        program_name: vm.request.program_name,
        status: vm.request.status
      }
      vm.$store.dispatch('ProgramUserModule/_getProgramUsers', payload)
        .then(response => {
          vm.response.programUsers = response.data.data.data
          vm.currentPage = response.data.data.current_page
          vm.totalPages = response.data.data.total
          vm.perPage = response.data.data.per_page;
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
            vm.$notify.error({
              title: 'Program Users',
              message: message
            })
          }
        })
        .finally(() => {
          vm.loaders.programUsers = false
        })
    },

    exportProgramUsers() {
      let vm = this
      vm.$store.commit('toggleDownloadReport', true)
      const payload = {
        username: vm.request.username,
        email: vm.request.email,
        program_code: vm.request.program_code,
        program_name: vm.request.program_name,
        status: vm.request.status
      }
      vm.$store.dispatch('ProgramUserModule/_exportProgramUsers', payload)
        .then(response => {
          vm.generateExportFileDownload(response, 'Program_Users')
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Program Users Export',
            message: message
          })
        })
        .finally(() => {
          vm.$store.commit('toggleDownloadReport', false)
        })
    },

    /**
     * Get status class
     * @param status
     * @returns {string}
     */
    getStatusClass(status) {
      return status === 0 ? 'Inactive-Status' : 'Active-Status'
    },

    /**
     * Get status as a string
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Handle the active button event
     */
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    /**
     * Handle the deactivate button event
     */
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    /**
     * Handle delete button event
     * @param index
     * @param row
     */
    handleDelete(index, row) {
      this.promptRemove(row)
    },

    /**
     * Prompt remove modal
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove this Program User.'
      this.removeId = row.id
    },

    /**
     * Prompt the Remove modal for multiple items
     */
    promptMultiRemove() {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove these Program Users.'
      this.removeId = this.extractSelectedIds
    },

    /**
     * Handle event for selecting multiple rows from table
     * @param selectedRows
     */
    sectionListMultipleSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    /**
     * Handle remove multiple items event
     */
    removeMultipleProgramUsers() {
      this.deleteRequest(this.removeId)
    },

    /**
     * Handle remove single item event
     * @param id
     */
    removeProgramUser(id) {
      this.deleteRequest(id)
    },

    /**
     * Delete Program User(s)
     * @param data
     */
    deleteRequest(data) {
      let vm = this
      vm.loaders.programUsers = true
      vm.loaders.checkboxButton.Delete = true
      const payload = {ids: Array.isArray(data) ? data : [data]}
      vm.$store.dispatch('ProgramUserModule/_deleteProgramUser', payload)
        .then(response => {
          vm.removeId = []
          vm.getProgramUsersListing()
          vm.$store.commit("hideRemoveModal")
          vm.$notify.success({
            title: 'Success',
            message: 'Program User Deleted Successfully.'
          })
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Delete Program User',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.checkboxButton.Delete = false
          vm.loaders.programUsers = false
        })
    },

    /**
     * Update the status for selected row(s) from table
     * @param ids
     * @param status
     */
    statusUpdateRequest(ids, status) {
      let vm = this
      vm.loaders.programUsers = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      }

      vm.$store.dispatch('ProgramUserModule/_updateStatusProgramUser', payload)
        .then(response => {
          vm.removeId = []
          vm.$notify.success({
            title: 'Success',
            message: 'Status Updated Successfully.'
          })
          vm.getProgramUsersListing()
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Program User Status',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.programUsers = false
        })
    },
  }
}
</script>

<style scoped>

</style>
